import { defineStyleConfig } from '@chakra-ui/styled-system';

const FormLabelTheme = defineStyleConfig({
  baseStyle: {
    fontSize: 'sm',
    marginBottom: '4px',
    fontWeight: '600',
    whiteSpace: 'nowrap',
  },
  sizes: {},
  variants: {},
  defaultProps: {},
});

export default FormLabelTheme;
