import type { ReportHandler } from 'web-vitals';

const reportWebVitals = async (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    try {
      const webVitalsModule = await import('web-vitals');
      const { getCLS, getFID, getFCP, getLCP, getTTFB } = webVitalsModule;

      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    } catch (error) {
      console.error('Error importing web-vitals:', error);
    }
  }
};

export default reportWebVitals;
