import type { ComponentSingleStyleConfig } from '@chakra-ui/react';

const TextTheme: ComponentSingleStyleConfig = {
  baseStyle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
  },
  sizes: {
    xxs: {
      fontSize: '10px',
      fontWeight: '400',
    },
    xs: {
      fontSize: '12px',
      fontWeight: '400',
    },
    sm: {
      fontSize: '14px',
      fontWeight: '400',
    },
    md: {
      fontSize: '16px',
      fontWeight: '400',
    },
    lg: {
      fontSize: '18px',
      fontWeight: '400',
    },
    xl: {
      fontSize: '20px',
      fontWeight: '500',
    },
    '2xl': {
      fontSize: '24px',
      fontWeight: '600',
    },
    '3xl': {
      fontSize: '28px',
      fontWeight: '600',
    },
    '4xl': {
      fontSize: '32px',
      fontWeight: '700',
    },
    '5xl': {
      fontSize: '36px',
      fontWeight: '700',
    },
  },
  variants: {
    default: {
      color: 'textBlack',
    },
    subHeading: {
      color: 'subtleSectionHeading',
      textTransform: 'uppercase',
      fontWeight: '600',
    },
    subSectionHeading: {
      letterSpacing: '-0.02px',
      color: '#393939',
      _hover: {
        fontWeight: '600',
      },
    },
    tableText: {
      color: 'textBlack',
      textAlign: 'center',
      wordBreak: 'break-word',
      whiteSpace: 'nowrap',
    },
  },
  defaultProps: {
    variant: 'default',
    size: 'md',
  },
};

export default TextTheme;
