import { type EffectCallback, type FC, memo, useEffect, useMemo, useRef } from 'react';
import getDisplayName from 'react-display-name';

import { areDeepEqual } from 'watchtower-ui/utils/utilityFunctions';

/* eslint-disable @typescript-eslint/no-explicit-any -- It is very difficult to determine a static type here. */
const useDeepCompareMemoize = (dependencies: any[]) => {
  const previousDependenciesRef = useRef<any[]>([]);
  const signalRef = useRef<number>(0);

  const hasChanged = dependencies.some((dep, index) => !areDeepEqual(dep, previousDependenciesRef.current[index]));
  if (hasChanged) {
    previousDependenciesRef.current = dependencies;
    signalRef.current += 1;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this only on signal ref changes.
  return useMemo(() => [signalRef.current], [signalRef.current]);
};

export const useDeepCompareEffect = (effect: EffectCallback, dependencies: any[]) =>
  // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this only on dependencies changes.
  useEffect(effect, useDeepCompareMemoize(dependencies));

export const useDeepMemo = <T,>(factory: () => T, dependencies: any[]) =>
  // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this only on dependencies changes.
  useMemo(factory, useDeepCompareMemoize(dependencies));

export const ReactMemo = <P extends object>(Component: FC<P>) => {
  const ComponentWithMemo: FC<P> = (props) => <Component {...props} />;
  ComponentWithMemo.displayName = `Memo${getDisplayName(Component as FC<unknown>)}`;

  const areEqual = (prevProps: Readonly<P>, nextProps: Readonly<P>) => areDeepEqual(prevProps, nextProps);

  return memo(ComponentWithMemo, areEqual);
};
