import type { ComponentSingleStyleConfig } from '@chakra-ui/react';

const HeadingTheme: ComponentSingleStyleConfig = {
  baseStyle: {},
  sizes: {
    heading1: {
      fontSize: '36px',
      fontWeight: '700',
    },
    heading2: {
      fontSize: '32px',
      fontWeight: '600',
    },
    heading3: {
      fontSize: '28px',
      fontWeight: '600',
    },
    heading4: {
      fontSize: '24px',
      fontWeight: '500',
    },
    heading5: {
      fontSize: '20px',
      fontWeight: '500',
    },
    heading6: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '135%',
    },
  },
  variants: {
    default: {
      color: 'textBlack',
    },
    markdownHeading: {
      color: 'textBlack',
      lineHeight: '24px',
    },
  },
  defaultProps: {
    variant: 'default',
    size: 'heading2',
  },
};

export default HeadingTheme;
