const colors = {
  primaryScheme: {
    50: '#fcf6ba',
    100: '#faf089',
    200: '#f8ed71',
    300: '#f7e958',
    400: '#f6e640',
    500: '#F4E00F',
    600: '#c7b609',
    700: '#afa008',
    800: '#968a07',
    900: '#655d05',
  },
  primary: '#FFFF00',
  primaryBlack: '#141414',
  pageBg: '#FAFAFA',
  navBg: '#F8FAFD',
  gradient1: '#e3af32',
  gradient2: '#EBD118',
  activeBrightGreen: '#8de301',
  table: { bg: '#F2F2F2', hover: '#DBDADA', pressed: '#999999' },
  textBlack: '#141414',
  textSecondary: '#1414148F',
  textGray: '#585858',
  border: '#1414141F',
  primaryBorder: '#FFDD00',
  subtleSectionHeading: '#a4a4a4',
  bg: {
    info: {
      secondary: 'rgba(231, 242, 255, 1)',
    },
    success: {
      secondary: 'rgba(225, 247, 230, 1)',
    },
    error: {
      secondary: 'rgba(252, 236, 235, 1)',
    },
    subtleLightGray: 'rgba(247, 247, 247, 1)',
    subtleDarkGray: 'rgba(237, 237, 237, 1)',
  },
  border_theme: {
    info: {
      secondary: 'rgba(213, 233, 254, 1)',
    },
    success: {
      secondary: 'rgba(204, 239, 212, 1)',
    },
    error: {
      secondary: 'rgba(255, 231, 229, 1)',
    },
  },
  icon: {
    on: {
      info: {
        secondary: 'rgba(15, 127, 250, 1)',
      },
      success: {
        secondary: 'rgba(49, 189, 84, 1)',
      },
      error: {
        secondary: 'rgba(229, 60, 50, 1)',
      },
    },
  },
} as const;
export default colors;
