import queryString from 'query-string';
import { useCallback, useMemo, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useDeepCompareEffect } from 'watchtower-ui/customHooks/useDeepComaprisionEffect';
import { areDeepEqual, findModifiedKeys, isDerivedValue } from 'watchtower-ui/utils/utilityFunctions';

const getQueryParams = (searchParams: URLSearchParams): queryString.ParsedQuery => {
  let queryParams: queryString.ParsedQuery = {};
  if (typeof window !== 'undefined') {
    queryParams = Object.fromEntries(searchParams);
  }
  return queryParams;
};

const setQueryParams = (
  query: queryString.ParsedQuery,
  setSearchParams: (queryParam: URLSearchParams) => void,
): void => {
  if (typeof window !== 'undefined') {
    const hasKeys = Object.keys(query).length > 0;
    const urlSuffix = hasKeys ? `?${queryString.stringify(query)}` : '';
    const newSearchParams = new URLSearchParams(urlSuffix);
    setSearchParams(newSearchParams);
  }
};

const removeQueryParamsAndUpdate = (
  query: queryString.ParsedQuery,
  key: string,
  setSearchParams: (queryParam: URLSearchParams) => void,
): void => {
  if (typeof window !== 'undefined') {
    delete query[key];
    const hasKeys = Object.keys(query).length > 0;
    const urlSuffix = hasKeys ? `?${queryString.stringify(query)}` : '';
    const newSearchParams = new URLSearchParams(urlSuffix);
    setSearchParams(newSearchParams);
  }
};

const useSetValueWithPrev = <T,>(
  preValue: T,
  initialValues: T,
  dontMergeValues: boolean,
  setValue: Dispatch<SetStateAction<T>>,
  customStateComparion?: (defaultValues: T, prevsValue: T, newValue: T) => T,
) =>
  useCallback<Dispatch<SetStateAction<T>>>(
    (latestVal: SetStateAction<T>) => {
      setValue(() => {
        const newValue = isDerivedValue(latestVal) ? latestVal(preValue) : latestVal;
        if (areDeepEqual(preValue, newValue)) return preValue;
        if (typeof initialValues === 'object' && typeof newValue === 'object' && dontMergeValues) {
          const modifiedKeys = findModifiedKeys(initialValues as object, newValue as object);
          if (modifiedKeys && modifiedKeys.length > 0) {
            const value = {
              ...(Object.fromEntries(
                modifiedKeys.map((name) => {
                  const typedKey = name as keyof typeof newValue;
                  return [name, newValue?.[typedKey] ?? null];
                }),
              ) as T),
            };
            return value;
          }
          return newValue;
        } else if (customStateComparion) {
          return customStateComparion(initialValues, preValue, newValue);
        } else {
          return newValue;
        }
      });
    },
    [setValue, preValue, initialValues, customStateComparion, dontMergeValues],
  );

const fetchValue = <T,>(initialsValues: T, searchParams: URLSearchParams, searchParamName: string): T => {
  const queryParams = getQueryParams(searchParams);
  const v = queryParams[searchParamName];
  if (initialsValues instanceof Date) {
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- json.parse will return of any type */
    return typeof v === 'string' ? (new Date(JSON.parse(v)) as T) : initialsValues;
  } else {
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-return -- json.parse will return of any type */
    return typeof v === 'string' ? JSON.parse(v) : initialsValues;
  }
};

const useQueryParamState = <T, Init extends T>(
  searchParamName: string,
  initialValues: Init,
  // eslint-disable-next-line  default-param-last -- dontmergevalue is an optional param
  dontMergeValues: boolean = false,
  customStateComparion?: (defaultValues: Init, prevsValue: Init, newValue: Init) => Init,
): [boolean, Init | NonNullable<Init>, Dispatch<SetStateAction<Init>>] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = getQueryParams(searchParams);
  const isInitialzed = queryParams[searchParamName];
  const [tempValue, setTempValue] = useState<Init>(fetchValue(initialValues, searchParams, searchParamName));

  const value: Init = useMemo(() => {
    if (typeof initialValues === 'object' && !Array.isArray(initialValues) && !(initialValues instanceof Date)) {
      return {
        ...initialValues,
        ...tempValue,
      };
    }

    return tempValue ?? initialValues;
  }, [tempValue, initialValues]);
  const setValueWithPrev = useSetValueWithPrev(
    value,
    initialValues,
    dontMergeValues,
    setTempValue,
    customStateComparion,
  );

  useDeepCompareEffect(() => {
    if (!areDeepEqual(initialValues, tempValue)) {
      setQueryParams(
        { ...getQueryParams(searchParams), [searchParamName]: JSON.stringify(tempValue) },
        setSearchParams,
      );
    } else {
      removeQueryParamsAndUpdate(getQueryParams(searchParams), searchParamName, setSearchParams);
    }
  }, [tempValue, initialValues]);

  return [Boolean(isInitialzed), value, setValueWithPrev];
};

export default useQueryParamState;
