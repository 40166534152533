/* eslint-disable no-bitwise -- We want feature flags to be opaque */

import type { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

import useQueryParamState from 'watchtower-ui/customHooks/useQueryParamState';
import { isDerivedValue } from 'watchtower-ui/utils/utilityFunctions';

export const FEATURE_FLAG_QUERY_PARAM = 'expFeatFlags';

const FEATURE_FLAGS = {
  DEVELOPER_LOGS: 1, // 1 << 0
  HELP_PAGE: 2, // 1 << 1
  SHOW_ERROR_BOUNDARY_REPORT_BUTTON: 4, // 1 << 2
  SHOW_COLORS_PATTERNS: 8, // 1 << 3
  SHOW_UPPER_BOUND: 16, // 1 << 4
  SHOW_CUSTOM_MONTH: 32, // 1 << 5
  SHOW_WHOLESALER_FILTER: 64, // 1 << 6
  SHOW_ALL_DATES: 128, // 1 << 7
} as const;

type FeatureFlag = keyof typeof FEATURE_FLAGS;

export type FeatureFlagState = { [K in FeatureFlag]?: boolean };
export type FullFeatureFlagState = { [K in FeatureFlag]: boolean };

const toFeatureFlagState = (featureFlagInt: number): FeatureFlagState =>
  Number.isInteger(featureFlagInt)
    ? Object.fromEntries(
        Object.entries(FEATURE_FLAGS)
          .filter(([, v]) => (featureFlagInt & v) !== 0)
          .map(([k]) => [k, true]),
      )
    : {};

const toFeatureFlagInt = (featureFlagState: FeatureFlagState) =>
  Object.entries(featureFlagState)
    .filter(([k, v]) => Object.keys(FEATURE_FLAGS).includes(k) && v)
    .reduce((prev, [k]) => prev | FEATURE_FLAGS[k as FeatureFlag], 0);

type FeatureFlagContextValue = {
  featureFlags: FeatureFlagState;
  fullFeatureFlags: FullFeatureFlagState;
  setFeatureFlags?: Dispatch<SetStateAction<FeatureFlagState>>;
};

const FeatureFlagContext = createContext<FeatureFlagContextValue>({
  featureFlags: {},
  fullFeatureFlags: Object.fromEntries(Object.keys(FEATURE_FLAGS).map((k) => [k, false])) as FullFeatureFlagState,
});

export const FeatureFlagContextManager: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  const [, featureFlagInt, setFeatureFlagInt] = useQueryParamState(FEATURE_FLAG_QUERY_PARAM, 0);
  const featureFlagState = useMemo(() => toFeatureFlagState(featureFlagInt), [featureFlagInt]);
  const fullFeatureFlagState = useMemo(
    () =>
      Object.fromEntries(
        Object.keys(FEATURE_FLAGS).map((k) => [k, Boolean(featureFlagState[k as FeatureFlag])]),
      ) as FullFeatureFlagState,
    [featureFlagState],
  );
  useEffect(() => {
    if (import.meta.env.DEV || fullFeatureFlagState.DEVELOPER_LOGS) {
      console.debug('Feature flag state', fullFeatureFlagState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We explicitly only want to refresh when the source state changes
  }, [featureFlagInt]);
  const setFeatureFlagState = useCallback<Dispatch<SetStateAction<FeatureFlagState>>>(
    (newVal) => {
      if (isDerivedValue(newVal)) {
        setFeatureFlagInt((cur) => toFeatureFlagInt(newVal(toFeatureFlagState(cur ?? 0))));
      } else {
        setFeatureFlagInt(toFeatureFlagInt(newVal));
      }
    },
    [setFeatureFlagInt],
  );
  const contextValue = useMemo<FeatureFlagContextValue>(
    () => ({
      featureFlags: featureFlagState,
      fullFeatureFlags: fullFeatureFlagState,
      setFeatureFlags: setFeatureFlagState,
    }),
    [featureFlagState, fullFeatureFlagState, setFeatureFlagState],
  );
  return <FeatureFlagContext.Provider value={contextValue}>{children}</FeatureFlagContext.Provider>;
};

export const useFeatureFlag = (
  featureFlag: FeatureFlag,
): [boolean, { set: Dispatch<SetStateAction<boolean>>; toggle: () => void }] => {
  const featureFlagValue = useContextSelector(FeatureFlagContext, (context) => context.fullFeatureFlags[featureFlag]);
  const setFeatureFlags = useContextSelector(FeatureFlagContext, (context) => context.setFeatureFlags);
  const setFeatureFlagValue = useCallback<Dispatch<SetStateAction<boolean>>>(
    (newVal) => {
      if (setFeatureFlags) {
        if (isDerivedValue(newVal)) {
          setFeatureFlags((cur) => ({ ...cur, [featureFlag]: newVal(Boolean(cur[featureFlag])) }));
        } else {
          setFeatureFlags((cur) => ({ ...cur, [featureFlag]: newVal }));
        }
      }
    },
    [featureFlag, setFeatureFlags],
  );
  const toggleFeatureFlagValue = useCallback(() => {
    if (setFeatureFlags) {
      setFeatureFlags((cur) => ({ ...cur, [featureFlag]: !cur[featureFlag] }));
    }
  }, [featureFlag, setFeatureFlags]);
  return [featureFlagValue, { set: setFeatureFlagValue, toggle: toggleFeatureFlagValue }];
};
