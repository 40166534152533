import type { ComponentSingleStyleConfig } from '@chakra-ui/react';

const InputTheme: ComponentSingleStyleConfig = {
  baseStyle: {
    color: 'textBlack',
    background: 'white',
    focusBorderColor: 'primaryBlack',
    _focus: {
      outline: 'none',
    },
  },
  sizes: {},
  defaultProps: {},
};

export default InputTheme;
