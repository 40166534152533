import { EventType, PublicClientApplication, type SilentRequest } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { datadogRum } from '@datadog/browser-rum';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import 'watchtower-ui/index.css';
import App from 'watchtower-ui/App';
import msalConfig from 'watchtower-ui/authConfig';
import { ErrorBoundaryComponent } from 'watchtower-ui/components/common/ErrorBoundary/ErrorBoundary';
import reportWebVitals from 'watchtower-ui/reportWebVitals';

const msalInstance = new PublicClientApplication(msalConfig);

if (!import.meta.env.VITE_AUTOMATION_TOKEN) {
  // eslint-disable-next-line no-void -- Need to tell it to explicitly ignore the result of this function
  void (async () => {
    await msalInstance.initialize();

    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]!);
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback((event) => {
      const account = (event.payload as SilentRequest)?.account;
      if (event.eventType === EventType.LOGIN_SUCCESS && account) {
        msalInstance.setActiveAccount(account);
      }
    });
  })();
}

const initializeDataDog = () => {
  if (!import.meta.env.DEV)
    datadogRum.init({
      applicationId: 'c43ddb1c-7a3e-4bc4-8770-c6e49d51804d',
      clientToken: 'pubde4be9a39a6e4ed0326addd92e677f5d',
      site: 'datadoghq.com',
      service: 'watchtower-ui',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- assign local to to env
      env: import.meta.env.DEV ? 'local' : import.meta.env.ENVIRONMENT_NAME ?? 'local',
      // Specify a version number to identify the deployed version of your application in Datadog
      version: '0.1.0',
      trackLongTasks: true,
      trackResources: true,
      trackSessionAcrossSubdomains: true,
      trackUserInteractions: true,
      trackingConsent: 'granted',
      enablePrivacyForActionName: false,
      useSecureSessionCookie: true,
      sessionReplaySampleRate: 100,
      traceSampleRate: 100,
      telemetrySampleRate: 20,
      defaultPrivacyLevel: 'allow',
      allowedTracingUrls: [/https:\/\/watchtower.*\.ab-inbev\.com/],
    });
};

const renderApp = () => {
  const container = document.getElementById('root');

  if (!container) throw new Error('Failed to find the root element');

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call -- asd
  const root = createRoot(container);

  if (import.meta.env.DEV) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access -- asd
    root.render(
      <StrictMode>
        <ErrorBoundaryComponent>
          {import.meta.env.VITE_AUTOMATION_TOKEN ? (
            <App />
          ) : (
            <MsalProvider instance={msalInstance}>
              <App />
            </MsalProvider>
          )}
        </ErrorBoundaryComponent>
      </StrictMode>,
    );
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access -- asd
    root.render(
      import.meta.env.VITE_AUTOMATION_TOKEN ? (
        <App />
      ) : (
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      ),
    );
  }
};

// Perform the health check before rendering the app using async/await
const initializeApp = () => {
  try {
    renderApp();
  } catch (error) {
    console.error('Initialization failed due to API health check:', error);
    throw error;
  }
};

// eslint-disable-next-line no-void -- Need to tell it to explicitly ignore the result of this function
void initializeApp();

// eslint-disable-next-line no-void -- Need to tell it to explicitly ignore the result of this function
void reportWebVitals();

initializeDataDog();
