import { extendTheme } from '@chakra-ui/react';

import colors from 'watchtower-ui/theme/colors';
import ButtonTheme from 'watchtower-ui/theme/components/Button';
import CheckboxTheme from 'watchtower-ui/theme/components/Checkbox';
import ContainerTheme from 'watchtower-ui/theme/components/Container';
import FormLabelTheme from 'watchtower-ui/theme/components/FormLabel';
import HeadingTheme from 'watchtower-ui/theme/components/Heading';
import InputTheme from 'watchtower-ui/theme/components/Input';
import TextTheme from 'watchtower-ui/theme/components/Text';
import TooltipTheme from 'watchtower-ui/theme/components/Tooltip';
import zIndices from 'watchtower-ui/theme/zIndices';

const theme = extendTheme({
  colors,
  zIndices,
  components: {
    Button: ButtonTheme,
    Input: InputTheme,
    Heading: HeadingTheme,
    FormLabel: FormLabelTheme,
    Checkbox: CheckboxTheme,
    Text: TextTheme,
    Container: ContainerTheme,
    Tooltip: TooltipTheme,
  },
  shadows: {
    outline: 'black',
    light1: '0px 0px 4px 0px #00000029',
    light4: '0px 0px 16px 0px #00000029',
  },
  fonts: {
    heading: `'Barlow', sans-serif`,
    body: `'Work Sans', sans-serif`,
  },
  layerStyles: {
    paper: {
      bg: 'white',
      boxShadow: '0px 0px 4px 0px #00000029',
      borderRadius: '16px',
      padding: '24px',
    },
    gradient: {
      bg: 'linear-gradient(0deg, #e3af32 0%, #EBD118 100%)',
    },
    card: {
      bg: 'white',
      boxShadow: '0px 0px 20px 0px #0000000D',
      border: '1px solid #0000001A',
      borderRadius: '10px',
      padding: '20px',
    },
  },
});
export default theme;
