import { defineStyleConfig } from '@chakra-ui/styled-system';

const ButtonTheme = defineStyleConfig({
  baseStyle: {
    borderRadius: 'full',
    fontWeight: '500',
  },
  sizes: {
    sm: {
      height: '32px',
      px: '16px',
      fontSize: '14px',
      width: 'auto',
    },
    md: {
      height: '40px',
      px: '16px',
      fontSize: '16px',
      width: 'auto',
    },
    lg: {
      height: '48px',
      px: '24px',
      fontSize: '18px',
      width: 'auto',
    },
  },
  variants: {
    primary: {
      background: 'primaryBlack',
      color: 'white',
      _hover: {
        color: 'primary',
      },
      _active: {
        color: 'textGray',
      },
    },
    secondary: {
      border: '1px solid',
      borderColor: 'primaryBlack',
      color: 'primaryBlack',
      _hover: {
        bg: 'primary',
      },
      _active: {
        color: '#14141466',
      },
    },
    tertiary: {
      bg: '#eaeaea',
      _hover: {
        bg: 'primary',
      },
      _active: {
        bg: '#14141429',
        color: '#14141466',
      },
    },
    outline: {
      background: '#fff',
      border: '1px solid #000',
      color: '#000',
      fontSize: '14px',
      fontWeight: '500',
      transition: 'all 0.2s ease-in-out',
      _hover: {
        background: 'linear-gradient(#f5e003, #e5b611)',
      },
    },
    warning: {
      background: 'red',
      color: 'white',
      _hover: {
        color: 'primary',
      },
      _active: {
        color: 'textGray',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
});

export default ButtonTheme;
