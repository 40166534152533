import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// eslint-disable-next-line @typescript-eslint/unbound-method -- recommended by Chakra-UI
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

// default base style from the Checkbox theme
const baseStyle = definePartsStyle({
  label: {},
  control: {},
  icon: {},
  container: {},
});

const primary = definePartsStyle({
  label: {},
  control: {
    bgColor: 'darkerOffGray',
    borderRadius: '4px',
    borderColor: 'rgba(20,20,20,0.4)',
    _hover: {
      borderColor: 'rgba(20,20,20,1)',
    },
    _indeterminate: {
      bgColor: 'rgba(20,20,20,1)',
      borderColor: 'rgba(20,20,20,1)',
      _hover: {
        bgColor: 'rgba(20,20,20,0.9)',
        borderColor: 'rgba(20,20,20,1)',
      },
    },
    _checked: {
      bgColor: 'rgba(20,20,20,1)',
      borderColor: 'rgba(20,20,20,1)',
      _hover: {
        bgColor: 'rgba(20,20,20,0.9)',
        borderColor: 'rgba(20,20,20,1)',
      },
    },
  },
  icon: {
    color: 'white',
  },
  container: {},
});

const sizes = {
  xl: definePartsStyle({
    control: {
      w: '22px',
      h: '22px',
    },
    icon: {
      w: '12px',
      h: '12px',
    },
  }),
  lg: definePartsStyle({
    control: {
      w: '20px',
      h: '20px',
    },
    icon: {
      w: '10px',
      h: '10px',
    },
  }),
  md: definePartsStyle({
    control: {
      w: '18px',
      h: '18px',
    },
    icon: {
      w: '8px',
      h: '8px',
    },
  }),
};

const CheckboxTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    primary,
  },
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
  sizes,
});

export default CheckboxTheme;
