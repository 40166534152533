import { defineStyleConfig } from '@chakra-ui/styled-system';

const ContainerTheme = defineStyleConfig({
  baseStyle: {
    maxW: '1600px',
  },
  sizes: {},
  variants: {},
  defaultProps: {},
});

export default ContainerTheme;
