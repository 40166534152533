import type { ComponentSingleStyleConfig } from '@chakra-ui/react';

const TooltipTheme: ComponentSingleStyleConfig = {
  baseStyle: {
    boxShadow: '0px 0px 4px 0px #00000029',
    borderRadius: '4px',
    py: '4px',
    px: '8px',
  },
  variants: {},
  sizes: {},
  defaultProps: {},
};

export default TooltipTheme;
