import { lazy, useEffect } from 'react';
import { createBrowserRouter, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { FeatureFlagContextManager } from 'watchtower-ui/contexts/FeatureFlagContext';
import Unauthorized from 'watchtower-ui/pages/Unauthorized/Unauthorized';

const DynamicDashboardPage = lazy(() => import('watchtower-ui/pages/HistoricalPages/DynamicDashboardPage'));
const HistoricalHome = lazy(() => import('watchtower-ui/pages/HistoricalPages/HistoricalHome'));
const HistoricalKpi = lazy(() => import('watchtower-ui/pages/HistoricalPages/kpi/HistoricalKpi'));
const MomentumAnalysis = lazy(() => import('watchtower-ui/pages/HistoricalPages/momentum/MomentumAnalysis'));
const HistoricalWaterfall = lazy(() => import('watchtower-ui/pages/HistoricalPages/waterfall/HistoricalWaterfall'));
const RegionalInsights = lazy(() => import('watchtower-ui/pages/HistoricalPages/regional/RegionalInsights'));
const LearnedCurves = lazy(() => import('watchtower-ui/pages/HistoricalPages/curves/LearnedCurves'));
const HistoricalDecomp = lazy(() => import('watchtower-ui/pages/HistoricalPages/decomp/HistoricalDecomp'));
const InputImpactInsights = lazy(() => import('watchtower-ui/pages/HistoricalPages/input_impact/InputImpactInsights'));
const OptimizationPageContainer = lazy(() => import('watchtower-ui/pages/OptimizationPages/OptimizationPageContainer'));
const OptimizationHome = lazy(() => import('watchtower-ui/pages/OptimizationPages/OptimizationHome'));
const OptimizationPlanning = lazy(() => import('watchtower-ui/pages/OptimizationPages/planning/OptimizationPlanning'));
const OptimizationPlanCurve = lazy(() => import('watchtower-ui/pages/OptimizationPages/view_plan_curve/ViewPlanCurve'));
const OptimizationDeepDiveCurve = lazy(
  () => import('watchtower-ui/pages/OptimizationPages/curve_deep_dive/CurveDeepDive'),
);
const Homepage = lazy(() => import('watchtower-ui/pages/Homepage/Homepage'));
const DocumentationPage = lazy(() => import('watchtower-ui/pages/Documentation/DocumentationPage'));

const redirectWithQuery = (to: string) => {
  const Component = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    useEffect(() => navigate(`${to}${search}`, { replace: true }), [navigate, search]);
    return null;
  };
  return Component;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <FeatureFlagContextManager>
        <Outlet />
      </FeatureFlagContextManager>
    ),
    children: [
      {
        index: true,
        Component: Homepage,
      },
      {
        path: 'help/:path',
        Component: DocumentationPage,
        handle: {
          label: 'Documentation',
        },
      },
      {
        path: 'historical',
        Component: DynamicDashboardPage,
        handle: {
          label: 'Historical Home',
        },
        children: [
          {
            index: true,
            Component: HistoricalHome,
          },
          {
            path: 'waterfall',
            Component: HistoricalWaterfall,
            handle: {
              label: 'Waterfall',
            },
          },
          {
            path: 'momentum',
            Component: MomentumAnalysis,
            handle: {
              label: 'Momentum Analysis',
            },
          },
          {
            path: 'kpi',
            Component: HistoricalKpi,
            handle: {
              label: 'Combine KPI',
            },
          },
          {
            path: 'regional',
            Component: RegionalInsights,
            handle: {
              label: 'Regional Insights',
            },
          },
          {
            path: 'decomp',
            Component: HistoricalDecomp,
            handle: {
              label: 'Historical Decomposition',
            },
          },
          {
            path: 'roicurve',
            Component: redirectWithQuery('/optimization/plan'),
          },
          {
            path: 'input_impact',
            Component: redirectWithQuery('../input-impact'),
          },
          {
            path: 'input-impact',
            Component: InputImpactInsights,
            handle: {
              label: 'Input Impact Insights',
            },
          },
          {
            path: 'learned-curves',
            Component: LearnedCurves,
            handle: {
              label: 'Learned Curves',
            },
          },
        ],
      },
      {
        path: 'optimization',
        Component: OptimizationPageContainer,
        handle: {
          label: 'Optimization Home',
        },
        children: [
          {
            index: true,
            Component: OptimizationHome,
          },
          {
            path: 'plan',
            Component: Outlet,
            handle: {
              label: 'Create New Plan',
            },
            children: [
              {
                index: true,
                Component: OptimizationPlanning,
              },
              {
                path: 'view',
                Component: Outlet,
                handle: {
                  label: 'View Curve For Plan',
                },
                children: [
                  {
                    index: true,
                    Component: OptimizationPlanCurve,
                  },
                  {
                    path: 'deep-dive',
                    Component: OptimizationDeepDiveCurve,
                    handle: {
                      label: 'View Deep Dive For Plan',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'unauthorized',
        Component: Unauthorized,
      },
    ],
  },
]);

export default router;
